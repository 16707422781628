@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.socials
    margin-top: 3px
    display: inline

    img
        height: 20px

    .facebook
        background-color: #3b5999

    .linkedin
        background-color: #0077B5

    .youtube
        background-color: #cd201f
